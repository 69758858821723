import React from 'react'
// 
import '../../STYLESHEETS/Footer.css'
// 
import { BsFacebook } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className=' bg1 color2 font1'>
      <h1>John Ramirez Violin</h1>
      <p className='footer-desc'>
        Violin lessons in the Hollister, Gilroy, and Morgan Hill areas. 30 years of teaching experience and great results from students all over California. Violin is an essential part of child's life and must be developed as early as 3 years old.
      </p>
      <div className='footer-links'>
        <Link className='footer-link color2' to="/">Home</Link>
        <Link className='footer-link color2' to="/about">About</Link>
        <Link className='footer-link color2' to="/contact">Contact Me</Link>
      </div>
      <div className='footer-divider'></div>
      <div className='footer-bottom'>
        <p className='footer-copy color3'>
          &copy; John Ramirez Violin 2023. All Rights Reserved.
        </p>
        <div className='socials'>
          <a href="" className='social-link'><BsFacebook color="1871E6" /></a>
          <a href="" className='social-link'><AiFillInstagram color="C12A79" /></a>
        </div>
      </div>
    </footer>
  )
}
