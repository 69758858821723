export const c_businessName = "John Ramirez Violin"
// 
export const emailjs_publicKey = "eaOYb8X6nqSrLTHBS"
export const emailjs_serviceID = "service_j2fxsia_violinj"
export const emailjs_fromEmail = "violinjohn6@hotmail.com"
export const emailjs_replyTo = "violinjohn62hotmail.com"
// 
export const emailjs_schedule_templateID = ""
export const emailjs_schedule_message = `Thank you for scheduling an appointment with ${c_businessName}. Please make sure to arrive 10 minutes before your confirmed time to not lose your place in line. Otherwise, you can schedule another time for a different date.`
// 
export const emailjs_contact_templateID = "template_6ez9gil"
export const emailjs_myContact_templateID = "template_6v4x43c"
export const emailjs_contact_message = `Your inquiry has been successfully received. A follow up email will be sent shortly.`
// 
export const square_appID = ""
export const square_locationID = ""
export const square_accessToken = ""