import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/About.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'

export default function About() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "About", Views: 0 })
    }, [])
    return (
        <div className='main'>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="about font1">
                <h1 className='page-title'>Meet your instructor</h1>
                <div className='about-wrap'>
                    <div className='about-section'>
                        <div>
                            <h2 className='about-sub'>John Ramirez</h2>
                            <div className='about-img'></div>
                        </div>
                        <div className='about-text'>
                            <p>John studied at the Julliard School of Music with a full scholarship where he received his advanced tutelage by the legendary violin teacher Dorothy Delay.  </p>
                            <p>He was inspired to pursue his musical career at a young age by his father, violinist Margarito Ramirez. For the past 30 years, John’s career as a music educator has allowed him to create, transform and influence many important music programs in San Diego County.
                            </p>
                            <p>His contributions in creating a music curriculum include the Coronado School of the Arts and Canyon Crest Academy. Also, John introduced multiple music programs throughout the San Diego County including 32 private and public schools teaching over 10,000 students on various musical instruments. In addition to John's many years of teaching through his successful private studio, he has instructed and mentored numerous students who have pursued successful professional music careers as performers and teachers.</p>
                            <p>John was also the conductor of the San Diego Youth Symphony and Conservatory for 10 years. Later, he became Founder and Artistic Director of the New Youth Orchestra of San Diego, implementing 4 orchestras, 3 wind ensembles and 3 entry-level music programs. During this time John developed a unique multi-tiered orchestra and wind training program, which housed over 400 students for the intense musical training of all levels. The New Youth Orchestra of San Diego was recognized as the cutting-edge music program in Southern California.</p>
                            <p>John has now planted roots in Hollister to be closer to his family. He has plans of implementing a high caliber instructional music program in Morgan Hill.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
